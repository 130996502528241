import React, { Component, Fragment, createRef } from "react";
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import _ from 'lodash';

import {ReactComponent as PinImg} from '../../../assests/icons/new-locationPin.svg'; 
import backButton from "../../../v5-icons/back-button.svg";

import GoogleMap from '../GoogleMap/GoogleMap';
import EllipseIcon from '../../icons/ellipse-icon';
import LocationSearchConfirm from '../LocationSearchConfirm/LocationSearchConfirm';
import LocationSearchCurrent from '../LocationSearchCurrent/LocationSearchCurrent';
import TitleBar from "../../../organisms/title-bar";
import LocationSearchInput from "../LocationSearchInput";
import LocationSearchSuggestions from "../LocationSearchSuggestions/LocationSearchSuggestions";

import {
	mapGetCurrentLocation,
	fetchSearchPlacesGoogle,
	checkRouteThenInitMap,
	setLocationOnMapWithSearch,
	confirmSelectedAddress,
} from "../../../utils/redux/actions/mapActions";
import {
	mapRemoveGoogleMarker,
	setGooglePlaceId,
	setGooglePlaceObject,
} from "../../../utils/redux/actions/mapActions/mapChangeState";
import { closeLocationSearchBottomSheet } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { editLocationSavedPlace, savedPlaceUnSetSearchLocationPointer } from "../../../utils/redux/actions/savedPlacesActions/savedPlacesChangeState";
import { searchNavigateToMapFromLocationSearchBookingDispatch } from "../../../utils/redux/actions/searchActions/searchChangeState";
import { setNavigationBlackBox } from "../../../utils/redux/actions/commonActions/commonChangeState";
import { setAddressDetailsForm } from "../../../utils/redux/actions/savedPlacesActions";
import {
	localStorageSetIsFirstRequest,
	localStorageSetIsLocationFromMap,
} from "../../../utils/functions/local-storage";
const classNames = require('classnames');

class SetLocationWithMap extends Component {
	finish = createRef();
	locationSearchInputRef = createRef();
	constructor(props) {
		super(props);

		this.state = {
			isSearching: false,
			fromSearch: false,
			isLocationSearchInputFocused: true,
			isKeyboardOpen: true,
		};

		this.onConfirm = this.onConfirm.bind(this);
		this.onGetCurrent = this.onGetCurrent.bind(this);
	}

	componentDidMount() {
		this.timeoutId = setTimeout(() => {
			this.locationSearchInputRef.current.focus();
		}, 1250);

		this.props.dispatch(checkRouteThenInitMap(this.finish, this.props.match.params));
	}

	componentWillUnmount() {
		this.props.dispatch(mapRemoveGoogleMarker());
		clearTimeout(this.timeoutId);
	}

	onConfirm(e) {
		localStorageSetIsLocationFromMap();

		this.props.dispatch(confirmSelectedAddress(this.props.location.pathname, this.props.match.params, this.state.fromSearch, this.props.savedPlaces.searchPointer));

		if (this.props.booking.locationSearchBottomSheet && this.props.search.searchLocationWithMap) {
			this.props.dispatch(savedPlaceUnSetSearchLocationPointer());
			this.props.dispatch(closeLocationSearchBottomSheet());
			this.props.dispatch(searchNavigateToMapFromLocationSearchBookingDispatch(false));

		}
		// this.props.dispatch(setGooglePlaceId(null));
	}

	onGetCurrent(e) {
		this.props.dispatch(mapGetCurrentLocation());
	}

	onSearchPlace = (e) => {
		this.setState(() => ({ fromSearch: true, searchQuery: e.target.value }));
		this.props.dispatch(fetchSearchPlacesGoogle(e.target.value));
		this.changeStateOfSearching(e.target.value);
	};

	onClearQuery = () => {
		this.locationSearchInputRef.current.focus();
		this.locationSearchInputRef.current.value = "";
		this.setState({ isSearching: false })
	}

	/**
	 * Change state of searching by value is empty or not
	 */
	changeStateOfSearching = (value) => {
		if (value) {
			this.setState({ isSearching: true });
		} else {
			this.setState({ isSearching: false });
		}
	};

	onSearchClick = (e, index) => {
		this.setState(() => ({
			isSearching: false,
		}));

		this.props.dispatch(setLocationOnMapWithSearch(index, this.props.location.pathname, this.props.match.params));
	};

	onCancelSuggestions = () => {
		this.locationSearchInputRef.current.blur();
		this.setState({ isLocationSearchInputFocused: false, isSearching: false });
		this.locationSearchInputRef.current.value = "";
	}

	onLocationSearchInputFocus = () => {
		this.setState({ isLocationSearchInputFocused: true });
	}

	setParentState = (state) => {
		this.setState({isKeyboardOpen: state})
	}

	render() {
		return (
			<div className="location-search-map set-first-location">
				{this.props.location.pathname === "/map" ? (
					<TitleBar
						title="Set location on map"
						leftIcon={backButton}
						handleClickLeftIcon={() => {
							if (this.props.common.navigationBlackBox.from === "addAddress" ||
								this.props.common.navigationBlackBox.from === "editAddress") {
								this.props.dispatch(push(this.props.common.navigationBlackBox.goBack));
							} else {
								this.props.dispatch(push(!_.isNull(this.props.savedPlaces.searchPointer) ? this.props.savedPlaces.searchPointer : "/home"))
							}
						}}
					/>
				) : (
					<TitleBar
						title="Select your location"
						leftIcon={backButton}
						handleClickLeftIcon={() => {
							if (this.props.common.navigationBlackBox.from === "addAddress" ||
								this.props.common.navigationBlackBox.from === "editAddress") {
								this.props.dispatch(push(this.props.common.navigationBlackBox.goBack));
							} else {
								this.props.dispatch(setAddressDetailsForm(null));
								this.props.dispatch(push(this.props.savedPlaces.searchPointer === "/account" ? "/saved-places" : this.props.savedPlaces.searchPointer || "/home"))
							}
						}}
					/>
				)}

				<GoogleMap listener={true} url={this.props.match.path} searchAddressRef={this.locationSearchInputRef} />

				<div className={classNames("location-search-input", {"background": this.state.isKeyboardOpen || this.state.isSearching})}>
					<LocationSearchInput
						ref={this.locationSearchInputRef}
						placeholder="Search"
						autoComplete="false"
						onSearchPlace={this.onSearchPlace}
						onClearQuery={this.onClearQuery}
						isSearching={this.state.isSearching}
						isLocationSearchInputFocused={this.state.isLocationSearchInputFocused}
						setParentState={this.setParentState}
						onCancelSuggestions={this.onCancelSuggestions}
						onLocationSearchInputFocus={this.onLocationSearchInputFocus} />
				</div>

				{!_.isEmpty(this.props.map.googleSearchPlaces) && this.state.isSearching ? (
					<LocationSearchSuggestions
						isSearching={this.state.isSearching}
						onSearchClick={this.onSearchClick}
						places={this.props.map.googleSearchPlaces}
					/>
				) : null}


				{!_.isEmpty(this.props.map.finish) && !_.isNull(this.props.map.finishLeft) && _.isEmpty(this.props.map.googleMarker) ? (
					<div
						className={classNames({
							"location-search-map-icon": true,
							dragged: this.props.map.dragged,
						})}
					>
						<Fragment>
							<PinImg className="Pin-icon" refs={this.props.map.finish} />
							<EllipseIcon style={{ left: this.props.map.finishLeft + 16, top: this.props.map.finishTop + 35 }} />
						</Fragment>
					</div>
				) : null}

				<div className="location-search-map-confirm">
					<LocationSearchConfirm style={{ backgroundColor: "#0488FA" }} title="Confirm Location" onClick={this.onConfirm} />
				</div>

				<div className="location-search-map-current">
					<LocationSearchCurrent onGetCurrent={this.onGetCurrent} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
    map: state.map,
	booking: state.booking,
	search: state.search,
	savedPlaces: state.savedPlaces,
	common: state.common,
});

export default connect(mapStateToProps)(SetLocationWithMap);
